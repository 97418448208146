import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import HeroImage from './components/heroimage.vue'
import Parallax from './components/parallax.vue'
import Footer from './components/footer.vue'
import VWow from './components/vwowtest.vue'
// import VueStroll from 'vue-stroll'

Vue.use(Router)

Vue.component('hero-image', HeroImage);
Vue.component('vwow', VWow);
Vue.component('parallax', Parallax);
Vue.component('vfooter', Footer);

// Vue.component('vstroll', VueStroll);
import VueWow from 'vue-wow'
Vue.use(VueWow)

import VueAgile from 'vue-agile'

Vue.use(VueAgile)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// import VueAgile from 'vue-agile'
// Vue.use(VueAgile)

export default new Router({
  mode: 'history',
  routes: [
    /// root build
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/products',
      name: 'products',
      component: () => import(/* webpackChunkName: "products" */ './views/Products.vue')
    },
    {
      path: '/facilities',
      name: 'facilities',
      component: () => import(/* webpackChunkName: "facilities" */ './views/Facilities.vue')
    },
    {
      path: '/productionplants',
      name: 'productionplants',
      component: () => import(/* webpackChunkName: "productionplants" */ './views/Productionplants.vue')
    },
    {
      path: '/quarries',
      name: 'quarries',
      component: () => import(/* webpackChunkName: "quarries" */ './views/Quarries.vue')
    },
    {
      path: '/operationworkflow',
      name: 'operationworkflow',
      component: () => import(/* webpackChunkName: "operationworkflow" */ './views/Operationworkflow.vue')
    },
    {
      path: '/media',
      name: 'media',
      component: () => import(/* webpackChunkName: "media" */ './views/Media.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
    },
    {
      path: '/csr',
      name: 'csr',
      component: () => import(/* webpackChunkName: "csr" */ './views/CSR.vue')
    },
    {
      path: '/ourbrand',
      name: 'ourbrand',
      component: () => import(/* webpackChunkName: "ourbrand" */ './views/Ourbrand.vue')
    },
    {
      path: '/ourhistory',
      name: 'ourhistory',
      component: () => import(/* webpackChunkName: "ourhistory" */ './views/Ourhistory.vue')
    },
    {
      path: '/vision',
      name: 'vision',
      component: () => import(/* webpackChunkName: "vision" */ './views/Vision.vue')
    },
    {
      path: '/qualityserviceassurance',
      name: 'qualityserviceassurance',
      component: () => import(/* webpackChunkName: "qualityserviceassurance" */ './views/Qualityserviceassurance.vue')
    },
    {
      path: '/award',
      name: 'award',
      component: () => import(/* webpackChunkName: "award" */ './views/Award.vue')
    },
    {
      path: '/applications',
      name: 'applications',
      component: () => import(/* webpackChunkName: "application" */ './views/Application.vue')
    },
    {
      path: '/producttype',
      name: 'producttype',
      component: () => import(/* webpackChunkName: "producttype" */ './views/Producttype.vue')
    },
    {
      path: '/latestnews',
      name: 'latestnews',
      component: () => import(/* webpackChunkName: "latestnews" */ './views/Latestnews.vue')
    },
    {
      path: '/corporatevideos',
      name: 'corporatevideos',
      component: () => import(/* webpackChunkName: "corporatevideos" */ './views/Corporatevideos.vue')
    },
    {
      path: '/corporatebrochure',
      name: 'corporatebrochure',
      component: () => import(/* webpackChunkName: "corporatebrochure" */ './views/Corporatebrochure.vue')
    },
    {
      path: '/contactdetails',
      name: 'contactdetails',
      component: () => import(/* webpackChunkName: "contactdetails" */ './views/Contactdetails.vue')
    },
    {
      path: '/businessenquiry',
      name: 'businessenquiry',
      component: () => import(/* webpackChunkName: "businessenquiry" */ './views/Businessenquiry.vue')
    },
    {
      path: '/workwithus',
      name: 'workwithus',
      component: () => import(/* webpackChunkName: "workwithus" */ './views/Workwithus.vue')
    },
    {
      path: '/careforsociety',
      name: 'careforsociety',
      component: () => import(/* webpackChunkName: "careforsociety" */ './views/Careforsociety.vue')
    },

    //v2 build
    // {
    //   path: '/v2/',
    //   name: 'home',
    //   component: Home
    // },
    // {
    //   path: '/v2/about',
    //   name: 'about',
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // },
    // {
    //   path: '/v2/products',
    //   name: 'products',
    //   component: () => import(/* webpackChunkName: "products" */ './views/Products.vue')
    // },
    // {
    //   path: '/v2/facilities',
    //   name: 'facilities',
    //   component: () => import(/* webpackChunkName: "facilities" */ './views/Facilities.vue')
    // },
    // {
    //   path: '/v2/productionplants',
    //   name: 'productionplants',
    //   component: () => import(/* webpackChunkName: "productionplants" */ './views/Productionplants.vue')
    // },
    // {
    //   path: '/v2/quarries',
    //   name: 'quarries',
    //   component: () => import(/* webpackChunkName: "quarries" */ './views/Quarries.vue')
    // },
    // {
    //   path: '/v2/operationworkflow',
    //   name: 'operationworkflow',
    //   component: () => import(/* webpackChunkName: "operationworkflow" */ './views/Operationworkflow.vue')
    // },
    // {
    //   path: '/v2/media',
    //   name: 'media',
    //   component: () => import(/* webpackChunkName: "media" */ './views/Media.vue')
    // },
    // {
    //   path: '/v2/contact',
    //   name: 'contact',
    //   component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
    // },
    // {
    //   path: '/v2/csr',
    //   name: 'csr',
    //   component: () => import(/* webpackChunkName: "csr" */ './views/CSR.vue')
    // },
    // {
    //   path: '/v2/ourbrand',
    //   name: 'ourbrand',
    //   component: () => import(/* webpackChunkName: "ourbrand" */ './views/Ourbrand.vue')
    // },
    // {
    //   path: '/v2/ourhistory',
    //   name: 'ourhistory',
    //   component: () => import(/* webpackChunkName: "ourhistory" */ './views/Ourhistory.vue')
    // },
    // {
    //   path: '/v2/vision',
    //   name: 'vision',
    //   component: () => import(/* webpackChunkName: "vision" */ './views/Vision.vue')
    // },
    // {
    //   path: '/v2/qualityserviceassurance',
    //   name: 'qualityserviceassurance',
    //   component: () => import(/* webpackChunkName: "qualityserviceassurance" */ './views/Qualityserviceassurance.vue')
    // },
    // {
    //   path: '/v2/award',
    //   name: 'award',
    //   component: () => import(/* webpackChunkName: "award" */ './views/Award.vue')
    // },
    // {
    //   path: '/v2/applications',
    //   name: 'applications',
    //   component: () => import(/* webpackChunkName: "application" */ './views/Application.vue')
    // },
    // {
    //   path: '/v2/producttype',
    //   name: 'producttype',
    //   component: () => import(/* webpackChunkName: "producttype" */ './views/Producttype.vue')
    // },
    // {
    //   path: '/v2/latestnews',
    //   name: 'latestnews',
    //   component: () => import(/* webpackChunkName: "latestnews" */ './views/Latestnews.vue')
    // },
    // {
    //   path: '/v2/corporatevideos',
    //   name: 'corporatevideos',
    //   component: () => import(/* webpackChunkName: "corporatevideos" */ './views/Corporatevideos.vue')
    // },
    // {
    //   path: '/v2/corporatebrochure',
    //   name: 'corporatebrochure',
    //   component: () => import(/* webpackChunkName: "corporatebrochure" */ './views/Corporatebrochure.vue')
    // },
    // {
    //   path: '/v2/contactdetails',
    //   name: 'contactdetails',
    //   component: () => import(/* webpackChunkName: "contactdetails" */ './views/Contactdetails.vue')
    // },
    // {
    //   path: '/v2/businessenquiry',
    //   name: 'businessenquiry',
    //   component: () => import(/* webpackChunkName: "businessenquiry" */ './views/Businessenquiry.vue')
    // },
    // {
    //   path: '/v2/workwithus',
    //   name: 'workwithus',
    //   component: () => import(/* webpackChunkName: "workwithus" */ './views/Workwithus.vue')
    // },
    // {
    //   path: '/v2/careforsociety',
    //   name: 'careforsociety',
    //   component: () => import(/* webpackChunkName: "careforsociety" */ './views/Careforsociety.vue')
    // },
  ]
})
