<template>
  <div class="home">
    <div
      class="mobile-show"
      style="
      width: 100vw;
      height: calc(100vw * 0.125);
      position:absolute;
      top:0;
      background: url(./img/mobile_header_bg1.jpg) center top  / cover;
      background-repeat: no-repeat;
     "
    ></div>
    <hero-image></hero-image>
    <div class="slidebg tablet-offset"></div>
    <div class="slidetext tablet-offset">
      {{$t('body.landing_slide')}}
      <div style="margin-top:8px;">
        <router-link :to="'about'">
          <img
            class="buttongo"
            src="img/button_go.png"
            alt
            style="width:42px;height:42px;"
            @mouseenter="hilight"
            @mouseleave="hilight"
          />
        </router-link>
      </div>
    </div>
    <div class="container mobile-tablet-hide">
      <div
        style="
          height: 50px;
          top: 150px;
          position: relative;
          text-align: left;
          font-size:14px;
      "
      >
        Copyright ©
        <strong style="color:#005677;">Zantat Sdn. Bhd.</strong> All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
export default {
  name: "home",
  components: {
    HelloWorld
  },
  mounted() {
    let self = this;
    console.log("hello");
    // bulmaSteps.attach();
  },
  methods: {
    syncslide() {
      let self = this;
      console.log("syncslide");
      self.$refs.imagecarousel.goToNext();
    },
    hilight(e) {
      let el = e.target;
      el.classList.toggle("over");
    }
  }
};
</script>

<style lang="scss" scoped>
.buttongo {
  cursor: pointer;
  opacity: 0.5;
  transition: all 300ms ease-in-out;
}
.buttongo.over {
  opacity: 1;
  transition: all 300ms ease-in-out;
}
.slidebg {
  display: none;
  width: 100vw;
  height: 280px;
  background: #005677;
  mix-blend-mode: multiply;
  color: white;
  text-align: justify;
  padding: 16px;
}
.slidetext {
  width: 100vw;
  color: white;
  text-align: left;
  background: #005677;
  padding: 30px 16px;
  font-size: 22px;
  line-height: 29px;
}
@media screen and (min-width: 768px) {
  .slidebg {
    display: block;
    width: 375px;
    height: 320px;
    background: #005677;
    mix-blend-mode: multiply;
    position: absolute;
    // top: 150px;
    top: calc(150px + (50vw - 280px) / 2);
    right: calc((100vw - 1000px) / 2);
    color: white;
    text-align: justify;
    padding: 16px;
  }
  .slidetext {
    width: 375px;
    position: absolute;
    top: calc(150px + (50vw - 280px) / 2);
    right: calc((100vw - 1000px) / 2);
    background: none;
    color: white;
    text-align: left;
    padding: 30px;
    font-size: 22px;
    line-height: 29px;
  }
  .tablet-offset{
    right : 20px;
  }    
}
@media screen and (min-width: 992px) {
  .slidebg {
    display: block;
    width: 375px;
    height: 320px;
    background: #005677;
    mix-blend-mode: multiply;
    position: absolute;
    // top: 150px;
    top: calc(150px + (50vw - 280px) / 2);
    right: calc((100vw - 1000px) / 2);
    color: white;
    text-align: justify;
    padding: 16px;
  }
  .slidetext {
    width: 375px;
    position: absolute;
    top: calc(150px + (50vw - 280px) / 2);
    right: calc((100vw - 1000px) / 2);
    background: none;
    color: white;
    text-align: left;
    padding: 30px;
    font-size: 22px;
    line-height: 29px;
  }
  .tablet-offset{
    right :none; 
  }
}
</style>

