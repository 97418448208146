<template>
  <div class="parallax">
    <div class="container parallaxcontainer" :style="{height:containerheight}">
      <div
        class="parallaxhero"
        v-rellax="{speed: pspeed,}"
        :style="{background: `url(${imagelink})center center / cover`}"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "parallax",
  props: ["imagelink", "pspeed", "containerheight"]
};
</script>

<style scoped lang="scss">
.parallaxcontainer {
  overflow: hidden;
}
.parallaxhero {
  width: 100%;
  height: 600px;
}
</style>
