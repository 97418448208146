<template>
  <div id="app">
    <div class="container nav-size">
      <!-- <div id="nav">
        <router-link to="/">Home</router-link>|
        <router-link to="/about">About</router-link>
      </div>-->
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand" style="margin-left:0px;">
          <router-link :to="'/'">
            <img src="img/logo.png" class="logo-size" />
          </router-link>
          <!-- <a>
            <img src="@/assets/logo_zantat.png" width="132" />
          </a>-->

          <!-- <a role="button" class="navbar-burger">
            <span aria-hidden="true">123</span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>-->

          <div
            class="mobile-show"
            style="
          position:relative;
          margin-left:auto;
          padding:12px 16px 8px;
          "
            @click="toggleMobileMenu()"
          >
            <div>
              <img src="img/butt_burger.png" width="24" height="20" />
            </div>
          </div>
        </div>

        <div id="navbarBasic" class="navbar-menu">
          <div class="navbar-start"></div>
          <div class="navbar-end">
            <div class="nav-lang">
              <div class="lang-button" @click="changeEN"><div style="padding-top:1px;float:left;">ENGLISH&nbsp;</div></div> 
              <span class="lang-button"> | </span>
              <input
                width="54"
                style="
                position: relative;
                top: 3px;
                cursor:pointer;
                "
                @click="changeCN"
              type="image" src="img/simplified_chinese.png" onMouseOver="this.src='img/simplified_chinese_blue.png'" onMouseOut="this.src='img/simplified_chinese.png'">
              <!-- <img -->
              <!--   src="img/simplified_chinese_blue.png" -->
              <!--   alt -->
              <!--   width="54" -->
              <!--   style=" -->
              <!--   position: relative; -->
              <!--   top: 3px; -->
              <!--   cursor:pointer; -->
              <!--   " -->
              <!--   @click="changeCN" -->
              <!-- /> -->
            </div>
            <div class="soon-menu">
              <div class="dropdown">
                <button class="dropbtn">
                  <router-link :to="'about'">{{$t('button.aboutus')}}</router-link>
                </button>
                <div class="dropdown-content">
                  <router-link :to="'about'">{{$t('button.whoweare')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'ourbrand'">{{$t('button.ourbrand')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'vision'">{{$t('button.visionandmission')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'ourhistory'">{{$t('button.ourhistory')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link
                    :to="'qualityserviceassurance'"
                  >{{$t('button.qualityandserviceassurance')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'award'">{{$t('button.awardsandrecognition')}}</router-link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">
                  <router-link :to="'applications'">{{$t('button.products')}}</router-link>
                </button>
                <div class="dropdown-content">
                  <router-link :to="'applications'">{{$t('button.application')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'producttype'">{{$t('button.producttypes')}}</router-link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">
                  <router-link :to="'facilities'">{{$t('button.facilities')}}</router-link>
                </button>
                <div class="dropdown-content">
                  <router-link :to="'facilities'">{{$t('button.productionplants')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'quarries'">{{$t('button.quarries')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'operationworkflow'">{{$t('button.operationworkflow')}}</router-link>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">
                  <router-link :to="''">{{$t('button.media')}}</router-link>
                  <!-- <router-link :to="'latestnews'">{{$t('button.media')}}</router-link> -->
                </button>
                <div class="dropdown-content">
                  <div class="coming-soon" id="mediacomingsoon">
                   {{$t('button.comingsoon')}}
                    <div class="arrow"></div>
                  </div>
                  <!-- <div -->
                  <!--   style="color:#ffffffaa;cursor:pointer;padding:12px 16px;" -->
                  <!--   @click="gotoURL('https://www.facebook.com/ZantatSdnBhd/')" -->
                  <!-- >{{$t('button.latestnews')}}</div> -->
                  <a
                    href="https://www.facebook.com/ZantatSdnBhd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{$t('button.latestnews')}}</a>
                  <!-- <div -->
                  <!--   style="color:#ffffffaa;cursor:pointer;padding:12px 16px;" -->
                  <!--   @mouseover="comingSoon('#mediacomingsoon')" -->
                  <!--   @mouseout="comingSoon('#mediacomingsoon')" -->
                  <!-- >{{$t('button.latestnews')}}</div> -->
                  <!-- <router-link
                    :to="''"
                    @mouseover="comingSoon('#mediacomingsoon')"
                    @mouseout="comingSoon('#mediacomingsoon')"
                  >{{$t('button.latestnews')}}</router-link>-->
                  <!-- <router-link :to="'latestnews'">{{$t('button.latestnews')}}</router-link> -->
                  <div class="dropdown-content-divider">....</div>
                  <!-- <router-link :to="''">{{$t('button.corporatevideo')}}</router-link> -->
                  <router-link :to="'corporatevideos'">{{$t('button.corporatevideo')}}</router-link>
                  <div class="dropdown-content-divider">....</div>

                  <a
        v-if="this.$i18n.locale == 'cn'"
                    href="pdf/brochure_cn.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{$t('button.corporatebrochure')}}</a>
                  <a
        v-if="this.$i18n.locale == 'en'"
                    href="pdf/brochure_en.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{$t('button.corporatebrochure')}}</a>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">
                  <router-link :to="''">{{$t('button.csr')}}</router-link>
                  <!-- <router-link :to="'careforsociety'">{{$t('button.csr')}}</router-link> -->
                </button>

                <div class="dropdown-content">
                  <div class="coming-soon" id="carecomingsoon">
                   {{$t('button.comingsoon')}}
                    <div class="arrow"></div>
                  </div>
                  <!-- <div -->
                  <!--   @click="gotoURL('https://www.facebook.com/pg/ZantatSdnBhd/photos/?tab=album&#38;album_id=1646038132116770')" -->
                  <!--   style="color:#ffffffaa;cursor:pointer;padding:12px 16px;" -->
                  <!-- >{{$t('button.caringforsociety')}}</div> -->
                  <a
                    href="https://www.facebook.com/pg/ZantatSdnBhd/photos/?tab=album&album_id=1646038132116770"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{$t('button.caringforsociety')}}</a>
                  <!-- <div -->
                  <!--   style="color:#ffffffaa;cursor:pointer;padding:12px 16px;" -->
                  <!--   @mouseover="comingSoon('#carecomingsoon')" -->
                  <!--   @mouseout="comingSoon('#carecomingsoon')" -->
                  <!-- >{{$t('button.caringforsociety')}}</div> -->
                  <!-- <router-link :to="''">{{$t('button.caringforsociety')}}</router-link> -->
                  <!-- <router-link :to="'careforsociety'">{{$t('button.caringforsociety')}}</router-link> -->
                </div>
              </div>
              <div class="dropdown">
                <button class="dropbtn">
                  <router-link :to="'contactdetails'">{{$t('button.contact')}}</router-link>
                </button>
                <div class="dropdown-content">
                  <router-link :to="'contactdetails'">{{$t('button.contactdetails')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'businessenquiry'">{{$t('button.businessenquiry')}}</router-link>
                  <div class="dropdown-content-divider">....</div>
                  <router-link :to="'workwithus'">{{$t('button.workwithus')}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <!-- mobile -->
    <div class="mobile-drop" id="mobile-menu">
      <div>
        <router-link :to="'about'" style="color:white;">
          <div class="cat" @click="toggleMobileMenu();topFunction();">{{$t('button.aboutus')}}</div>
        </router-link>

        <router-link :to="'about'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.whoweare')}}</div>
        </router-link>

        <router-link :to="'ourbrand'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.ourbrand')}}</div>
        </router-link>

        <router-link :to="'vision'">
          <div
            class="pg"
            @click="toggleMobileMenu();topFunction();"
          >{{$t('button.visionandmission')}}</div>
        </router-link>

        <router-link :to="'ourhistory'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.ourhistory')}}</div>
        </router-link>

        <router-link :to="'qualityserviceassurance'">
          <div
            class="pg"
            @click="toggleMobileMenu();topFunction();"
          >{{$t('button.qualityandserviceassurance')}}</div>
        </router-link>

        <router-link :to="'award'">
          <div
            class="pg"
            @click="toggleMobileMenu();topFunction();"
          >{{$t('button.awardsandrecognition')}}</div>
        </router-link>
      </div>
      <div>
        <router-link :to="'applications'" style="color:white;">
          <div class="cat" @click="toggleMobileMenu();topFunction();">{{$t('button.products')}}</div>
        </router-link>

        <router-link :to="'applications'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.application')}}</div>
        </router-link>

        <router-link :to="'producttype'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.producttypes')}}</div>
        </router-link>
      </div>
      <div>
        <router-link :to="'facilities'" style="color:white;">
          <div class="cat" @click="toggleMobileMenu();topFunction();">{{$t('button.facilities')}}</div>
        </router-link>

        <router-link :to="'facilities'">
          <div
            class="pg"
            @click="toggleMobileMenu();topFunction();"
          >{{$t('button.productionplants')}}</div>
        </router-link>

        <router-link :to="'quarries'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.quarries')}}</div>
        </router-link>

        <router-link :to="'operationworkflow'">
          <div
            class="pg"
            @click="toggleMobileMenu();topFunction();"
          >{{$t('button.operationworkflow')}}</div>
        </router-link>
      </div>
      <div>
        <router-link :to="''" style="color:white;">
          <div class="cat">{{$t('button.media')}}</div>
        </router-link>

        <router-link :to="''">
          <div class="pg">{{$t('button.latestnews')}} (Coming soon)</div>
        </router-link>

        <router-link :to="'corporatevideos'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.corporatevideo')}}</div>
        </router-link>

        <div class="pg" @click="toggleMobileMenu();topFunction();">
          <a
            href="pdf/brochure_en.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >{{$t('button.corporatebrochure')}}</a>
        </div>
      </div>
      <div>
        <router-link :to="''" style="color:white;">
          <div class="cat" @click="toggleMobileMenu();topFunction();">{{$t('button.csr')}}</div>
        </router-link>

        <router-link :to="''">
          <div class="pg">{{$t('button.caringforsociety')}} (Coming soon)</div>
        </router-link>
      </div>
      <div>
        <router-link :to="'contactdetails'" style="color:white;">
          <div class="cat" @click="toggleMobileMenu();topFunction();">{{$t('button.contact')}}</div>
        </router-link>

        <router-link :to="'contactdetails'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.contactdetails')}}</div>
        </router-link>

        <router-link :to="'businessenquiry'">
          <div
            class="pg"
            @click="toggleMobileMenu();topFunction();"
          >{{$t('button.businessenquiry')}}</div>
        </router-link>

        <router-link :to="'workwithus'">
          <div class="pg" @click="toggleMobileMenu();topFunction();">{{$t('button.workwithus')}}</div>
        </router-link>
      </div>
    </div>
    <!-- mobile -->
    <router-view />
    <div class="scrollup" id="scrollup" @click="topFunction()">
      <font-awesome-icon icon="arrow-up" id="plusbutton" />
    </div>
  </div>
</template>

<script>
import EventBus from "./eventbus";
export default {
  name: "HeroImage",
  props: {
    msg: String
  },
  mounted() {
    let self = this;
    // console.log(this.$i18n.locale);
    // this.$i18n.locale = "en";

    if (navigator.appVersion.indexOf("Win") != -1) {
    if  ( this.$i18n.locale == "cn" ){
//      console.log("windows os");
      $(".dropdown-content").css("top", "30px");
      $(".dropbtn").css("padding", "6px 16px");
    }else{

      $(".dropdown-content").css("top", "25px");
      $(".dropbtn").css("padding", "6px 16px");
    }
    } else {
      console.log("other os");
      $(".dropdown-content").css("top", "25px"); // this will style body for other OS (Linux/Mac)
      $(".dropbtn").css("padding", "6px 16px 0");
    }


    EventBus.$on("changeEN", function() {
      self.changeToEN();
    });
    EventBus.$on("changeCN", function() {
      self.changeToCN();
    });
    EventBus.$on("topFunction", function() {
      self.topFunction();
    });

    // When the user scrolls down 20px from the top of the document, show the button
    document.body.onscroll = function(e) {
      self.scrollFunction();
    };
  },
  methods: {
    scrollFunction() {
      var scrollup_button = document.getElementById("scrollup");
      if (window.pageYOffset > 20) {
        scrollup_button.style.display = "block";
      } else {
        scrollup_button.style.display = "none";
      }
    },
    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
      console.log("top function");
      $("html, body").animate({ scrollTop: 0 }, 400);
    },
    toggleMobileMenu() {
      console.log("mobile toggle");
      // let self = this;
      // var scrollup_button = document.getElementById("mobile-menu");
      $(".mobile-drop").toggleClass("show");
    },
    changeCN(){

      EventBus.$emit("changeCN");
    },
    changeEN(){
      EventBus.$emit("changeEN");

    },
    changeToEN(){
      this.$i18n.locale = "en";
      $(".dropdown-content").css("top", "25px");
      $(".dropbtn").css("padding", "6px 16px 0");
    },
    changeToCN(){
      this.$i18n.locale = "cn";
      $(".dropdown-content").css("top", "30px");
      $(".dropbtn").css("padding", "6px 16px ");
    },
    comingSoon(s) {
      $(s).toggleClass("show");
    },
    gotoURL(s) {
      console.log(s)
      window.open(s,'_blank')
    }
  }
};
</script>

<style lang="scss" scoped>
.lang-button{
color: #bfbfbf;
cursor: pointer;
display: contents;
}
.lang-button:hover{
color: #005677;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
  }
a {
  text-decoration: none;
  decoration: none;
  color: #005677;
}

// a:hover {
//   color: white;
// }
#app {
  // font-family: "Glyphicons Halflings Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #005677;
  font-family: 'Avenir','Noto Sans SC';
}
#nav {
  padding: 30px;
  a {
    font-weight: 900;
    color: #005677;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.navbar {
  margin-top: 50px;
  background: none;
}
.navbar-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  /* margin-left: auto; */
}
.navbar-menu {
  margin: 0 !important;
  // margin-top: 16px;
  display: none;
}
.dropbtn {
  min-width: 126px;
  flex: 1;
  font-family: "AvenirBold";
  background-color: #f1f1f2;
  color: #013d57;
  font-weight: 900;
  padding: 6px 16px 0;
  font-size: 16px;
  border: none;
  margin-right: 1px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.coming-soon {
  position: absolute;
  display: none;
  top: -37px;
  left: 0px;
  font-size: 0.8em;
  background: #d0d2d3;
  padding: 8px 18px;
  color: #013d57;
  min-width: 126px;
  .arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    height: 10px;
    width: 10px;
    background: #d0d2d3;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 300ms ease-in-out;
  }
}
.coming-soon.show {
  position: absolute;
  display: block;
  font-size: 0.8em;
  top: -37px;
  left: 0px;
  font-size: 0.8em;
  background: #d0d2d3;
  padding: 8px 18px;
  color: #013d57;
  min-width: 126px;
  .arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    height: 10px;
    width: 10px;
    background: #d0d2d3;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 300ms ease-in-out;
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(1, 61, 87, 0.9);
  border-top: solid #fff 1px;
  border-radius: 0;
  width: 126px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  font-family: "Avenir";
  color: #ffffffaa;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  color: #ffffff;
  // padding: 12px 16px;
  // text-decoration: none;
  // display: block;
}
.dropdown-content-divider {
  color: #fff;
  font-size: 20px;
  height: 0;
  position: relative;
  top: -18px;
  content: "....";
}
// .dropdown-content a:hover {
//   background-color: #023d70aa;
// }

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #013d57;
  color: #ddd;
  font-weight: 600;
  a {
    color: #ffffff;
  }
}
.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: -100px;
  right: 10px;
  padding-top: 8px;
  border-radius: 5px;
  background: #f1f1f2;
  display: none;
  cursor: pointer;
}
.logo-size {
  width: 140px;
  margin-left: 10px;
  top: -10px;
  position: relative;
}
.nav-size {
  height: 68px;
}
.mobile-drop {
  width: 100vw;
  position: relative;
  top: 10px;
  text-align: left;
  height: 0px;
  overflow: hidden;
  transition: all 300ms 0ms ease-in-out;
}
.mobile-drop.show {
  width: 100vw;
  position: relative;
  top: 10px;
  height: 960px;
  overflow: hidden;
  transition: all 300ms 0ms ease-in-out;
}
.cat {
  height: 40px;
  background: #005677;
  color: white;
  padding: 6px 12px;
  border-bottom: #fff solid 1px;
}
.pg {
  height: 40px;
  background: #ddd;
  color: #005677;
  padding: 6px 24px;
  border-bottom: #fff solid 1px;
}
.nav-lang {
  text-align: right;
  margin-right: 6px;
  margin-bottom: 22px;
  font-size: 14px;
  font-weight: 900;
  position: absolute;
  right: 10px;
  bottom: 64px;
  display: none;
}
@media screen and (min-width: 768px) {
  .nav-lang {
    text-align: right;
    margin-right: 6px;
    margin-bottom: 22px;
    font-size: 14px;
    font-weight: 900;
    position: absolute;
    right: 10px;
    bottom: 64px;
    display: block;
  }
  .logo-size {
    width: 150px;
    height: 75px;
  }
  .nav-size {
    height: 100px;
  }
  .scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding-top: 8px;
    border-radius: 5px;
    background: #f1f1f2;
    display: none;
    cursor: pointer;
  }
  .navbar-menu {
    margin: 0 !important;
    // margin-top: 16px;
    display: block;
    background: #f1f1f2;
  }
}
@media screen and (min-width: 1024px) {
  .nav-lang {
    text-align: right;
    margin-right: 6px;
    margin-bottom: 22px;
    font-size: 14px;
    font-weight: 900;
    position: absolute;
    right: 10px;
    bottom: 40px;
    display: block;
  }
  .soon-menu {
    display:inline;
    float: right;
    margin-top: 47px;
  }
  .logo-size {
    width: 140px;
    margin-left: 0px;
    top: 0px;
    position: relative;
  }
  .navbar-menu {
    margin: 0 !important;
    // margin-top: 16px;
    display: block;
    background: none;
  }
  .navbar-end {
    display: block;
  }
}
</style>
