<template>
  <u-animate-container>
    <u-animate
      name="fadeInUp"
      delay="0s"
      duration="1s"
      :iteration="1"
      :offset="0"
      animateClass="animated"
      :begin="false"
    >测试</u-animate>
  </u-animate-container>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>