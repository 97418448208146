<template>
  <div class="heroimage">
    <div class="mobile-tablet-hide">
      <agile
        ref="imagecarousel"
        :autoplay="false"
        :speed="2000"
        :fade="true"
        :dots="false"
        :nav-buttons="false"
        style="height:50vw;overflow:hidden;"
      >
        <div
          class="slide"
          style="background: url('./img/landing_slide01.jpg') center center / cover;height:50vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide02.jpg') center center / cover;height:50vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide03.jpg') center center / cover;height:50vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide04.jpg') center center / cover;height:50vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide05.jpg') center center / cover;height:50vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide06.jpg') center center / cover;height:50vw;"
        ></div>
      </agile>
      <div
        style="
      width: 100%;
      height: 17.6vw;
      position: absolute;
      top: calc(45vw + 150px);
      background: url(./img/landing_overlay.png) center center / cover;
      mix-blend-mode:multiply;
      "
      ></div>
      <div
        style="
    position:absolute; 
    top:calc(150px + 50vw - (((50vw - 280px) / 2) + 110px));
    left: calc((100vw - 1000px) / 2);
    "
      >
        <agile
          :autoplay="true"
          :speed="2000"
          :autoplay-speed="5000"
          :fade="true"
          :dots="true"
          :nav-buttons="false"
          style="height:35vw;overflow:hidden;"
          @beforeChange="syncslide"
        >
          <div class="slide" style="width:100%;height:35vw;">
            <div class="slide-overlay">
              <img src="img/chinese_persist.png" alt width="48" />
            </div>
          </div>
          <div class="slide" style="width:100%;height:35vw;">
            <div class="slide-overlay">
              <img src="img/chinese_grow.png" alt width="48" />
            </div>
          </div>
          <div class="slide" style="width:100%;height:35vw;">
            <div class="slide-overlay">
              <img src="img/chinese_expand.png" alt width="48" />
            </div>
          </div>
          <div class="slide" style="width:100%;height:35vw;">
            <div class="slide-overlay">
              <img src="img/chinese_trust.png" alt width="48" />
            </div>
          </div>
          <div class="slide" style="width:100%;height:35vw;">
            <div class="slide-overlay">
              <img src="img/chinese_perpetuate.png" alt width="48" />
            </div>
          </div>
          <div class="slide" style="width:100%;height:35vw;">
            <div class="slide-overlay">
              <img src="img/chinese_promise.png" alt width="48" />
            </div>
          </div>
        </agile>
      </div>
    </div>
    <div class="mobile-tablet-show">
      <agile
        ref="imagecarousel-mobile"
        :autoplay="true"
        :speed="2000"
        :fade="true"
        :dots="false"
        :nav-buttons="false"
        style="height:120vw;overflow:hidden;"
      >
        <div
          class="slide"
          style="background: url('./img/landing_slide01.jpg') center center / cover;height:120vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide02.jpg') center center / cover;height:120vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide03.jpg') center center / cover;height:120vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide04.jpg') center center / cover;height:120vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide05.jpg') center center / cover;height:120vw;"
        ></div>
        <div
          class="slide"
          style="background: url('./img/landing_slide06.jpg') center center / cover;height:120vw;"
        ></div>
      </agile>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "home",

  mounted() {
    let self = this;
    console.log("hello");
    // bulmaSteps.attach();
  },
  methods: {
    syncslide() {
      let self = this;
      console.log("syncslide");
      self.$refs.imagecarousel.goToNext();
    }
  }
};
</script>

<style lang="scss" scoped>
.slidebg {
  width: 300px;
  height: 250px;
  background: #005677;
  mix-blend-mode: multiply;
  position: absolute;
  top: 100px;
  right: 0;
  color: white;
  text-align: justify;
  padding: 16px;
}
.slidetext {
  width: 300px;
  height: 250px;
  position: absolute;
  top: 100px;
  right: 0;
  color: white;
  text-align: justify;
  padding: 16px;
}
.slide-overlay {
  width: 300px;
  height: inherit;
  font-family: "Yuan";
  font-size: 45px;
  line-height: 1;
  text-align: left;
  // margin-left: 200px;
  color: white;
  border-left: white solid 1px;
  position: relative;
  padding-left: 10px;
}

.slide-overlay::before {
  content: "";
  display: block;
  width: 4px;
  height: 100px;
  background: white;
  position: absolute;
  padding-left: 4px;
  top: 0;
  left: 0;
}
.agile__dots {
  bottom: 300px;
  flex-direction: column;
  right: 30px;
  position: absolute;
}
.agile__dot {
  margin: 5px 0;
}
.agile button {
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile--current button,
.agile:hover button {
  background-color: #fff;
}
</style>

