<template>
  <div class="vfooter">
    <div class="container tablet-hide">
      <div class="herofooter">
        <div
          style="    
        text-align: right;
        padding: 8px 16px;
        height:40px;
        "
        >
          <font-awesome-icon icon="plus" id="plusbutton" @click="toggleFooter" />
        </div>
        <div class="columns">
          <div
            class="column footerlink header"
            style="flex: 1.5;
            position:relative;
    border-right: white solid 1px; 
    margin-right:60px;"
          >
            <div @click="topFunction();">
              <router-link :to="'/'">{{$t('button.home')}}</router-link>
            </div>
            <div
              style="
            position:absolute;
            top:50%;
            transform:translateY(-50%)
            "
            >
              <div @click="topFunction();">
                <div  class="unselectable" :to="''">{{$t('body.footer_international')}}</div>
              </div>
              <div @click="changeEN" style="cursor:pointer;">English</div>
              <div>
                <img src="img/chinese_footer_simplified.png" width="52" @click="changeCN" style="cursor:pointer;" />
              </div>
            </div>
            <div style="
            position:absolute;
            bottom:0;
            ">
              <div @click="topFunction();">
                <div class="unselectable" :to="''">{{$t('body.footer_connect')}}</div>
              </div>
              <div>
                <a href="https://www.facebook.com/ZantatSdnBhd" target="blank">
                  <img src="img/footer_facebook.png" width="20" style="margin-right:6px;" />
                </a>
                <img src="img/footer_youtube_off.png" width="20" />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="footerlink header" @click="topFunction();">
              <router-link :to="'about'">{{$t('button.aboutus')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'about'">{{$t('button.whoweare')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'ourbrand'">{{$t('button.ourbrand')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'vision'">{{$t('button.visionandmission')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'ourhistory'">{{$t('button.ourhistory')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link
                :to="'qualityserviceassurance'"
              >{{$t('button.qualityandserviceassurance')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'award'">{{$t('button.awardsandrecognition')}}</router-link>
            </div>
          </div>
          <div class="column">
            <div class="footerlink header" @click="topFunction();">
              <router-link :to="'applications'">{{$t('button.products')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'applications'">{{$t('button.application')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'producttype'">{{$t('button.producttypes')}}</router-link>
            </div>
          </div>
          <div class="column">
            <div style="width:100px;">
              <div class="footerlink header" @click="topFunction();">
                <router-link :to="'facilities'">{{$t('button.facilities')}}</router-link>
              </div>
              <div class="footerlink" @click="topFunction();">
                <router-link :to="'facilities'">{{$t('button.productionplants')}}</router-link>
              </div>
              <div class="footerlink" @click="topFunction();">
                <router-link :to="'quarries'">{{$t('button.quarries')}}</router-link>
              </div>
              <div class="footerlink" @click="topFunction();">
                <router-link :to="'operationworkflow'">{{$t('button.operationworkflow')}}</router-link>
              </div>
            </div>
          </div>
          <div class="column">
            <div style="width:100px;">
              <div class="footerlink header" @click="topFunction();">
                <div  class="unselectable" style="color:#fff;">{{$t('button.media')}}</div>
                <!-- <router-link   :to="'media'">{{$t('button.media')}}</router-link> -->
              </div>
              <div class="footerlink" @click="topFunction();">
                <a
                  href="https://www.facebook.com/ZantatSdnBhd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{$t('button.latestnews')}}</a>
                <!-- <div  class="unselectable" style="color:#6d6e70;">{{$t('button.latestnews')}}</div> -->
                <!-- <router-link   :to="'latestnews'">{{$t('button.latestnews')}}</router-link> -->
              </div>
              <div class="footerlink" @click="topFunction();">
                <!-- <div style="color:#6d6e70;">{{$t('button.corporatevideo')}}</div> -->
                <router-link :to="'corporatevideos'">{{$t('button.corporatevideo')}}</router-link>
              </div>
              <div class="footerlink" @click="topFunction();">
                <a
        v-if="this.$i18n.locale == 'cn'"
                  href="pdf/brochure_cn.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{$t('button.corporatebrochure')}}</a>
                <a
        v-if="this.$i18n.locale == 'en'"
                  href="pdf/brochure_en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{$t('button.corporatebrochure')}}</a>
                <!-- <router-link   :to="'corporatebrochure'">{{$t('button.corporatebrochure')}}</router-link> -->
              </div>
            </div>
          </div>
          <div class="column">
            <div style="width:100px;">
              <div class="footerlink header" @click="topFunction();">
                <div class="unselectable" style="color:#fff;">{{$t('button.csr')}}</div>
                <!-- <router-link   :to="'csr'">{{$t('button.csr')}}</router-link> -->
              </div>
              <div class="footerlink" @click="topFunction();">
                <a
                  href="https://www.facebook.com/pg/ZantatSdnBhd/photos/?tab=album&album_id=1646038132116770"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{$t('button.csr')}}</a>
                <!-- <div class="unselectable" style="color:#6d6e70;">{{$t('button.caringforsociety')}}</div> -->
                <!-- <router-link   :to="'careforsociety'">{{$t('button.caringforsociety')}}</router-link> -->
              </div>
            </div>
          </div>
          <div class="column">
            <div class="footerlink header" @click="topFunction();">
              <router-link :to="'contact'">{{$t('button.contact')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'contactdetails'">{{$t('button.contactdetails')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'businessenquiry'">{{$t('button.businessenquiry')}}</router-link>
            </div>
            <div class="footerlink" @click="topFunction();">
              <router-link :to="'workwithus'">{{$t('button.workwithus')}}</router-link>
            </div>
          </div>
        </div>
        <div
          style="text-align:right; padding-right:50px; padding-bottom:50px;position:relative;top:-25px; font-size:12px;"
        >
          Copyright ©
          <strong style="color:white;">Zantat Sdn. Bhd.</strong> All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "../eventbus";
export default {
  name: "vfooter",
  props: {
    msg: String
  },
  methods: {
    toggleFooter() {
      let self = this;
      $(".herofooter").toggleClass("hide");
    },
    changeEN(){
      EventBus.$emit("changeEN");

    },
    changeCN(){
      EventBus.$emit("changeCN");
    },
    topFunction() {
      console.log("footer: top function");
      // $("html, body").animate({ scrollTop: 0 }, 400);
      EventBus.$emit("topFunction");
    }
  }
};
</script>

<style scoped lang="scss">
/* router-link:hover{ */
/* color:white; */
/* } */
.herofooter {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 100%;
  height: 350px;
  background: #808284;
  color: white;
  overflow: hidden;
  transition: all 500ms ease-in-out;
  #plusbutton {
    cursor: pointer;
    transform: rotate(45deg);
    transition: all 100ms ease-in-out;
  }
}
.herofooter.hide {
  height: 40px;
  transition: all 500ms ease-in-out;
  #plusbutton {
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 100ms ease-in-out;
  }
}
// .svg-inline--fa.fa-w-14 {
//   width: 17px;
// }
.svg-inline--fa {
  position: relative;
  top: 4px;
  vertical-align: 0;
}
.columns {
  margin: 0 32px;
  font-size: 13px;
}
.column {
  text-align: left;
  padding-top: 0;
}
.footerlink {
  padding: 0 0 16px;
  line-height: 1.3;
}
.footerlink.header {
  padding: 0 0 16px;
  font-weight: 600;
  font-family: AvenirBold;
}
a {
  color: white;
}
a:hover {
  color: white;
  /* color: #6d6e70 !important; */
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
