import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import { request } from "http";

window.$ = require('jquery')
window.axios = require('axios')

require("./assets/main.scss");

window.bulmaSteps = require('../node_modules/bulma-steps/dist/js/bulma-steps.min.js');

Vue.config.productionTip = false;

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faPlus, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)
library.add(faPlus)
library.add(faArrowUp)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: "en",
  messages: {
    en: {
      button: {
        home: 'HOME',
        aboutus: 'ABOUT US',
        whoweare: 'Who We Are',
        ourbrand: 'Our Brand',
        visionandmission: 'Vision & Mission',
        ourhistory: 'Our History',
        qualityandserviceassurance: 'Quality & Service Assurance',
        awardsandrecognition: 'Awards & Recognition',
        products: 'PRODUCTS',
        application: 'Applications',
        producttypes: 'Product Types',
        facilities: 'FACILITIES',
        productionplants: 'Production Plants',
        quarries: 'Quarries',
        operationworkflow: 'Operation Workflow',
        media: 'MEDIA',
        latestnews: 'Latest News',
        corporatevideo: 'Corporate Video',
        corporatebrochure: 'Corporate Brochure',
        csr: 'CSR',
        caringforsociety: 'Caring For Society',
        contact: 'CONTACT',
        contactdetails: 'Contact Details',
        businessenquiry: 'Business Enquiry',
        workwithus: 'Work With Us',
        comingsoon: 'coming soon...'
      },
      slider_body: {
        landing: 'Zantat Sdn Bhd is one of the leading producers of Calcium Carbonate in Malaysia. We provide lasting value for our customers by delivering high-quality products and excellent services.',
        about: '',
        products: '',
        facilities: '',
      },
      body: {
        footer_international: "INTERNATIONAL",
        footer_connect: "CONNECT WITH US",
        landing_slide: 'Zantat Sdn Bhd is one of the leading producers of Calcium Carbonate in Malaysia. We provide lasting value for our customers by delivering high-quality products and excellent services.',
        about_slide: 'We take great care and patience in communicating with our customers and business partners to better understand their needs. We can be counted on to deliver valued customised solutions, leading them towards business sustainability and efficiency.',
        ///about
        aboutus_whoweare_title: 'Who We Are',
        aboutus_whoweare_p1: 'Zantat Sdn Bhd is one of the leading producers of Calcium Carbonate in Malaysia. We provide lasting value for our customers by delivering high-quality products and excellent services.',
        aboutus_whoweare_title2: 'DETERMINATION ',
        aboutus_whoweare_title3: 'TO EXCEL',
        aboutus_whoweare_p2_1: 'Founded in 1985, Zantat Sdn Bhd has since grown to be one of Malaysia’s leading producers of high-grade Calcium Carbonate powder and Calcium Carbonate dispersion products today. Helmed by a highly devoted team of over thirty years experience and industry know-how, we are known to create lasting value for our customers and business partners by delivering superior quality products and excellent services across diverse industries.',
        aboutus_whoweare_p2_2: 'With a determined mindset and unwavering commitment, we ensure our business operations are all carried out with utmost integrity, sincerity and efficiency.',
        aboutus_whoweare_p2_3: 'Constantly keeping abreast with the current industry trends, innovation and technology, Zantat is always gearing towards greater heights in our expansion to the international market scene, upholding the same vision and principles laid down by our founders.',
        ///brand
        aboutus_brand_title1: 'OUR BRAND ',
        aboutus_brand_title2: 'POSITIONING',
        aboutus_brand_title3: 'PURPOSE',
        aboutus_brand_title4: 'OUR ',
        aboutus_brand_title5: 'PERSONALITY PRISM',
        aboutus_brand_grey1: '品牌定位与宗旨',
        aboutus_brand_grey2: '品牌个性概观',
        aboutus_logo_bg1: 'RELIABLE',
        aboutus_logo_bg2: 'DETERMINED',
        aboutus_logo_bg3: 'RESILIENT',
        aboutus_logo_def_title: 'OUR',
        aboutus_logo_def_title2: 'LOGO',
        aboutus_logo_def01: 'The Zantat logo represents us at the highest level and is vitally important to our brand. It acts as a signature, an identifier and a stamp of quality. It is, and should always be, the most consistent component in our communications.',
        aboutus_logo_def02: 'Key characteristics of our identity',
        aboutus_logo_key1: 'Balance of stability and agility',
        aboutus_logo_key2: 'Reflects an appreciation of detail and precision',
        aboutus_logo_key3: 'Provides a unique, honest expression',
        aboutus_logo_key4: 'Sophistication, infused with passion and aspiration',
        aboutus_logo_def03: 'Our tagline, ',
        aboutus_logo_def04: 'RELIABLE | DETERMINED | RESILIENT, ',
        aboutus_logo_def05: 'works as a self declaration and as a way of being.',
        aboutus_logo_purpose1_1: 'Emphasizes our focus on ',
        aboutus_logo_purpose1_2: 'partnership',
        aboutus_logo_purpose2_1: 'Is ',
        aboutus_logo_purpose2_2: 'empowering ',
        aboutus_logo_purpose2_3: 'and ',
        aboutus_logo_purpose2_4: 'optimistic',
        aboutus_logo_purpose3_1: 'Is ',
        aboutus_logo_purpose3_2: 'practical ',
        aboutus_logo_purpose3_3: 'and ',
        aboutus_logo_purpose3_4: 'down-to-earth',
        aboutus_logo_purpose4_1: 'Reminds all employees of our ability to make ',
        aboutus_logo_purpose4_2: 'positive impact ',
        aboutus_logo_purpose4_3: 'every day',
        aboutus_brand_p1: 'Back by over three decades of experience and industry know-how, we create lasting value for our customers by providing high-quality products and excellent services across various industries.',
        aboutus_brand_p2: 'Driven by the core values of reliable, determined and resilient, we constantly strive to deliver high quality products and services to our customers. Our humble and sincere approach make us a better partner. We are attentive and we understand the dynamics of our industry. We keep things simple, we are hardworking and we act with swiftness and agility. We are constantly on the look out for more effective and efficient ways to support our customers and keep them up-to-date.',
        aboutus_brand_p3: 'At Zantat, we look upon our customers as business partners and personal friends. We are committed to provide customised and personalised supports to our customers to run and grow a successful business.',
        aboutus_brand_p4: 'Our purpose reflects our true goals, abilities and contributions. It connects us with the customers we serve and support, and reminds us of the valuable contributions we make each day to people’s lives.',
        // aboutus_brand_p5: 'Our brand positioning and purpose are the guideline to our every communication, action and interaction. By adhering to our brand personality, we ensure that we appropriately serve our customers as well as build a stronger Zantat.',
        aboutus_brand_p5: 'Our brand positioning and purpose are the guidelines to our communication, action and interaction. By adhering to our brand personality, we ensure that we appropriately serve our customers as well as building a stronger Zantat.',
        ///vision
        aboutus_vision_title: 'Vision & Mission',
        aboutus_vision_title1: 'Vision',
        aboutus_vision_p1: 'To establish our presence as a top manufacturer of Calcium Carbonate products and a reliable partner that provides optimum value to customers in diverse industries.',
        aboutus_mission_title: 'Mission',
        aboutus_mission_p1: 'To deliver on our promise of providing high-quality products and excellent services through strong customer relationships, innovative R&D, and state-of-the-art facilities.',

        ///award
        aboutus_award_title: 'AWARDS & RECOGNITION',
        aboutus_award_p1: 'We are proud to be recognised for our achievements as one of the industry leaders of Calcium Carbonate. These prestigious awards are a testament to our dedication towards excellence as we continue to deliver quality products and services to bring value to our customers.',
        aboutus_award_2007p1: 'BUSINESS OF THE YEAR AWARD 2007',
        aboutus_award_2007p2: 'GOLDEN BULL AWARD 2007',
        aboutus_award_2007p3: '1 of the 100 winners',

        aboutus_award_2008p1: 'GOLDEN BULL AWARD 2008',
        aboutus_award_2008p2: '1 of the 100 winners',

        aboutus_award_2009p1: 'GOLDEN BULL AWARD 2009',
        aboutus_award_2009p2: '1 of the 100 winners',

        aboutus_award_2010p1: 'GOLDEN BULL AWARD 2010',
        aboutus_award_2010p2: '1 of the 100 winners',

        aboutus_award_2011p1: 'GOLDEN BULL AWARD 2011',
        aboutus_award_2011p2: 'Ranked 9th of the Top Outstanding SMEs',

        aboutus_award_2012p1: 'GOLDEN BULL AWARD 2012',
        aboutus_award_2012p2: '1 of the 100 winners',

        aboutus_award_2013p1: 'GOLDEN EAGLE AWARD 2013',
        aboutus_award_2013p2: 'Ranked 8th of the Top Outstanding SMEs',

        aboutus_award_2015p1: 'GOLDEN EAGLE AWARD 2015',
        aboutus_award_2015p2: 'Ranked 3rd position in the category of Excellent Eagles',
        aboutus_award_2015p3: 'SIN CHEW BUSINESS EXCELLENCE AWARD 2015 FOR PRODUCT & SERVICE EXCELLENCE',

        aboutus_award_2016p1: 'GOLDEN EAGLE AWARD 2016',
        aboutus_award_2016p2: 'Ranked 3rd position in the category of Eminent Eagles',

        aboutus_award_2017p1: 'GOLDEN EAGLE AWARD 2017',
        aboutus_award_2017p2: 'Ranked 2nd position in the category of Eminent Eagles',

        aboutus_award_2018p1: 'GOLDEN EAGLE AWARD 2018',
        aboutus_award_2018p2: '1 of the top 20 in the category of Eminent Eagles',
        ///quality
        aboutus_quality_title: 'QUALITY & SERVICE ASSURANCE',
        aboutus_quality_p1: 'At Zantat, we pride ourselves in going above and beyond our customers. We are strongly adhered to the policy of “Providing cost saving solutions to create green and safe products”.',
        aboutus_quality_p2: 'Our commitment to quality and environment encompasses our products and services, which ensures the meeting of industry standards through the implementation of ISO9001:2015 quality management system, ISO14001:2015 environment management system, Hazard Analysis Critical Control Point (HACCP), Good Manufacturing Practice (GMP), and JAKIM Halal Certifications.',

        ///history
        aboutus_history_title: 'our history',
        aboutus_history_p1: 'Since 1986, Zantat has been a symbol of grit, hard work, determination and integrity. These enduring qualities have been the guiding principles of Zantat since day one and have allowed us to grow into one of the leading providers of Calcium Carbonate in Malaysia.',

        aboutus_history_1986p1: 'Zantat Kuala Lumpur plant commenced operations to manufacture coated calcium carbonate with ball mill and supplied to PVC and rubber industries.',

        aboutus_history_1997p1: 'Introduced a product specially developed for latex gloves industry — Ultra fine Precipitated Calcium Carbonate Powder with the brand name of SUPERLITE, functioning as an anti-tack agent.',

        aboutus_history_2000p1: 'Calrock, a sister company of Zantat, was acquired to produce coarse grade Calcium Carbonate powder.',

        aboutus_history_2002p1: 'Zantat KL Plant developed high value-added Calcium Carbonate dispersion specifically for the latex gloves industry. Zantat is the first Calcium Carbonate supplier in Malaysia to introduce filler into latex gloves industry.',

        aboutus_history_2005p1: 'Acquisition of a 10-acre limestone quarry in Ipoh, Perak.',

        aboutus_history_2010p1: '60-year mining licence granted and construction of Ipoh plant commenced.',

        aboutus_history_2011p1: 'Zantat’s first manufacturing plant in Ipoh commenced operations, with an annual production capacity of 90,000MT of Calcium Carbonate Powder.',

        aboutus_history_2012p1: 'Zantat began its market expansion overseas and made India as one of its target markets.',

        aboutus_history_2014p1: 'The second phase expansion of Zantat’s manufacturing plant in Ipoh was completed, increasing the annual production capacity to 180,000MT.',

        aboutus_history_2015p1: 'Total export quantity of the year reached 140,000MT.',

        aboutus_history_2016p1: 'Zantat celebrated its 30th anniversary.',

        aboutus_history_2017p1: 'Construction of third phase expansion initiated in the first quarter. The expansion consists of a 12,500 sq.ft. double storey office block and a 110,000 sq.ft. factory building.',

        aboutus_history_2018p1: 'The third phase expansion of Zantat’s manufacturing plant in Ipoh was completed and commenced operations in the third quarter to manufacture 100,000MT of premium grade Calcium Carbonate powder annually.',

        ///product
        product_title1: 'NATURAL ',
        product_title2: 'TREASURE',
        product_divider1: '品质',
        product_p1: 'Calcium Carbonate ',
        product_p1_2: ' exists in nature as a white calcite mineral found in limestone and other rocks. Processed Calcium Carbonate takes several forms, including natural or stearic acid coated Calcium Carbonate powder and pre-dispersed slurry.',
        product_title3: 'APPLICATIONS',
        product_p2_1: 'Both ',
        product_p2_2: 'Ground Calcium Carbonate (GCC) ',
        product_p2_3: 'and ',
        product_p2_4: 'Precipitated Calcium Carbonate (PCC) ',
        product_p2_5: 'are widely used in many industries as one of the raw material components for the production of, among others, filler masterbatch, pvc pipes, plastics, lates gloves, rubber, paints & coatings, paper, plastic films and etc.',

        product_s1_title: 'FILLER MASTERBATCH',
        product_s1_p1: 'Calcium Carbonate filler masterbatch is used to improve the physical and chemical properties of plastic products.',

        product_s2_title: 'PVC PIPES',
        product_s2_p1: 'In PVC pipes, Calcium Carbonate improves performance and strength while reducing expensive resin content. ',

        product_s3_title: 'PLASTICS',
        product_s3_p1: 'Calcium Carbonate improves impact strength in plastic products while providing high-gloss, smooth finish and whiteness.',

        product_s4_title: 'LATEX GLOVES',
        product_s4_p1: 'Calcium carbonate slurry act as a filler in the production of Natural Rubber and Nitrile latex gloves for cost reduction without compromising on the elongation and tensile strength.',

        product_s5_title: 'RUBBER',
        product_s5_p1: 'The physical characteristics of rubber, such as resistance and strength is enhanced with Calcium Carbonate.',

        product_s6_title: 'PAINTS & COATINGS',
        product_s6_p1: 'Pigments used by paints and coatings industry comprises Calcium Carbonate powder for colour control and cost reduction. ',

        product_s7_title: 'PAPER',
        product_s7_p1: 'Calcium Carbonate is used to enhance the whiteness and brightness of paper while reducing costs by acting as a substitute for fibre and pigments.',

        product_s8_title: 'OTHERS',
        product_s8_p1: 'Calcium Carbonate powder is widely used in various industries due to its versatile components.',

        product_type_title1: 'PRODUCT TYPES',
        product_type_s1_title: 'CALCIUM CARBONATE POWDER',
        product_type_s1_p1: 'Calcium Carbonate powder is typically used as fillers in the production of plastics, papers, paints, coatings and rubbers. It can also be used as functional filler to alter the chemical and physical properties of a final product. Its natural whiteness makes it one of the cheapest pigments to provide whitening of materials.',
        product_type_s2_title: 'CALCIUM CARBONATE DISPERSION',
        product_type_s2_p1: 'Calcium Carbonate is pre-dispersed in slurry form to be used as a filler material to achieve maximum savings in material and production costs. Our Calcium Carbonate dispersion is specially developed to be used as fillers in the latex gloves and paper industries.',

        ///facilities
        facilities_productionplants_title: 'Production Plants',
        facilitiesp1: 'To date, Zantat owns four manufacturing facilities. Three of which are strategically located in Ipoh, Perak, under Zantat Sdn Bhd and its sister company, Calrock Sdn Bhd, with a significant vast built up area of more than 220,000 sq.ft. and 48,305 sq.ft. respectively. Another factory is situated in Kepong, Kuala Lumpur, occupying 40,000 sq.ft. of operation space.',
        facilitiesp2: 'Our production plants are certified to ISO standard and equipped to produce a variety of Calcium Carbonate products with an emphasis on quality to meet the demands of a wide range of industries.',
        facilitiescp1: "拓展",
        facilitiescp2: "绩效",
        facilitiest1: 'Ipoh Plant',
        facilitiesp3: 'We established our manufacturing facility in Ipoh in 2000 and expanded our operations at a new plant in 2011 focusing on the production of Calcium Carbonate powder for plastics, paints and paper industries. The second and third phase expansion of our Ipoh plant was completed in 2014 and 2017 respectively, with the combined production capacity of 320,000MT.',
        facilitiesp3_1: 'We are located at ',
        facilitiesp3_2: 'PT24571 & PT21289, Kaw. Industri Batu Kapur Keramat Pulai, 31300 Kampung Kepayang, Perak, Malaysia. ',
        facilitiesp3_3: 'Find us in Google Maps',
        facilitiesp3_4: 'Tel. ',
        facilitiesp3_5: '+605 357 1853 / +605 357 1463',
        facilitiesp3_6: ' •  Fax. ',
        facilitiesp3_7: '+605 357 1407',
        facilitiest2: 'Kuala Lumpur Plant',
        facilitiesp4: 'Our very first plant in Kuala Lumpur commenced operations in 1986. It is here that we developed Calcium Carbonate dispersion specifically for the latex gloves industry, making Zantat the first Calcium Carbonate manufacturer to introduce filler for latex gloves. Today, our Kuala Lumpur plant is a dedicated facility for manufacturing Calcium Carbonate dispersion for the latex gloves industry with a production capacity of 60,000MT per annum.',
        facilitiesp4_1: 'We are located at ',
        facilitiesp4_2: 'Lot 1013-B, Jalan 2/32A, 6 ½ Miles, Kepong Industrial Area, Jalan Kepong, 52100 Kuala Lumpur, Malaysia.',
        facilitiesp4_3: 'Find us in Google Maps',
        facilitiesp4_5: '+603 6258 5552 / +603 6258 1985',
        facilitiesp4_7: '+603 6251 8766',
        facilities_quarries_title: 'Quarries',
        facilities_quarries_sp1: 'We own two limestone reserves, which mining lease come with a validity period until 2068 and 2070 respectively. The combined size of the two limestone reserves covers a land area of 25 acres with total volume of limestone estimated at 22 million tonnes.',

        facilities_workflow_title1: 'Operation',
        facilities_workflow_title2: 'Workflow',

        facilities_workflow_1_title1: 'Quarry Operation and Preparation',
        facilities_workflow_1_title2: 'of Feed Materials',
        facilities_workflow_1p1: 'Blasting of limestone quarry.',
        facilities_workflow_1p2: 'Boulders broken down by hydraulic breaker and then transferred to adjacent crushing plant for sizing into 6"x 9" limestone blocks.',
        facilities_workflow_1p3: '6"x 9" limestone blocks transferred to another crushing plant for washing and chipping into 40-60mm limestone chips.',
        facilities_workflow_1p4: '40–60mm limestone chips delivered to Zantat and stored in covered area with minimum inventory level of 20,000 tons.',
        facilities_workflow_2_title1: 'Production of Uncoated and',
        facilities_workflow_2_title2: 'Coated Calcium Carbonate Powder',
        facilities_workflow_2p1: '40–60mm limestone chips fed into conveyor for first stage of visual inspection and removal of unwanted materials/impurities.',
        facilities_workflow_2p2: '40–60mm limestone chips fed into conveyor for second stage of visual inspection and removal of unwanted materials/impurities.',
        facilities_workflow_2p3: 'Hammer mill processes the 40–60mm limestone chips into aggregates of 97% below 10mm.',
        facilities_workflow_2p4: 'Aggregates fed into individual mills for grinding and classifying into uncoated/natural Calcium Carbonate powder of various grades.',
        facilities_workflow_2p4_2: 'Finished product: Uncoated/ Natural Calcium Carbonate Powder.',

        facilities_workflow_3_title1: 'Stearic Acid Coating Process',
        facilities_workflow_3_title2: 'Natural Calcium Carbonate Powder',
        facilities_workflow_3p1: 'Stearic acid is melted and filtered before going through atomization process.',
        facilities_workflow_3p1_2: 'Finished product: Stearic acid Coated Calcium Carbonate.',


        facilities_workflow_4_title1: 'Production of Calcium Carbonate',
        facilities_workflow_4_title2: 'Dispersion',
        facilities_workflow_4p1: 'Mixing and deagglomeration of uncoated/natural Calcium Carbonate with dispersant and other chemical additives.',
        facilities_workflow_4p2: 'Visual inspection, removal of impurities and wet milling process.',
        facilities_workflow_4p3: 'Wet milling process.',
        facilities_workflow_4p4: 'Finished goods: Calcium Carbonate Dispersion.',


        facilities_workflow_5_title1: 'Delivery to Domestic ',
        facilities_workflow_5_title2: 'and Overseas Customers',
        facilities_workflow_5p1: 'Deliver to Domestic and Overseas Customers.',

        facilities_workflow_title2_1: 'QUALITY',
        facilities_workflow_title2_2: 'CONTROL ',
        facilities_workflow_title2_3: 'MEASURES',

        facilities_workflow_qctitle1_1: 'Calcium Carbonate',
        facilities_workflow_qctitle1_2: 'Powder',


        facilities_workflow_qc1: 'Samples retained every 2 hours from each mill for PSD Analysis, whiteness &amp; brightness monitoring, moisture content, DOP oil absorption.',
        facilities_workflow_qc2: 'Machine parameters monitored and recorded in conjunction with the sampling process.',
        facilities_workflow_qc3: 'Each pallet of goods is given a lot number with a sample retained in lab for future reference.',
        facilities_workflow_qc4: 'Available packaging: 25kg Paper bags, 600kg Jumbo bags, 1.25MT Jumbo bags.',

        facilities_workflow_qctitle2_1: 'Calcium Carbonate',
        facilities_workflow_qctitle2_2: 'Dispersion',

        facilities_workflow_qc2_1: 'Samples retained every 2 hours from each milling for in-process inspection (PSD Analysis, Total Solid Content, Viscosity and pH).',
        facilities_workflow_qc2_2: 'Each packaging of goods is given a lot number with a sample retained in lab for future reference.',
        facilities_workflow_qc2_3: 'Available Packaging: Metal Drums, IBC tanks, Bulk Tankers.',
        ///care for society
        news_title: "LATEST NEWS",
        news_p1: "绩效",
        news_np1: "Find out more in our ",
        news_np2: "FB Like Page",
        news_np3: "Zantat 30th Anniversary Employee and Business Partner Appreciation Dinner",

        ///care for society
        video_title: "CORPORATE VIDEOS",
        video_p1: "绩效",


        ///care for society
        care_title: "CARING FOR SOCIETY",
        care_p1: "We seek to make a positive contribution in the local community and the environment by encouraging our people to make a difference through organising of various engaging initiatives and events.",
        care_p2: "We believe working relationships among employees extends beyond the boundaries of the workplace. Visits to underprivileged children’s home, contribution to the special needs and disabled community, blood donation drives and gathering for sports recreation sessions are some of the initiatives we have organised with our people.",
        care_p3: "We ensure strong and solid relationships are fostered and build through these social platforms.",

        ///care for society
        contact_details_title: "CONTACT DETAILS",
        contact_klplant: "Zantat Sdn Bhd - Kuala Lumpur Plant",
        contact_ipohplant: "Zantat Sdn Bhd - Ipoh Plant",
        contact_klfactory: "Kuala Lumpur Plant",
        contact_ipohfactory: "Ipoh Plant",

        contact_business_title: "BUSINESS ",
        contact_business_title2: "ENQUIRY",
        contact_business_p1: "We welcome enquiries pertaining to potential business opportunities and partnership collaborations, Do connect with us for more information and discussion.",
        contact_business_p2: "You can count on us, always.",
        contact_business_p3: "联系",

        contact_work_title: "WORK WITH US",
        contact_work_p1: "We welcome any enquiry you may have as we are gearing up for more creative and professional talents. Discover how our esteemed organisation can unlock your potential and how you can be a positive contribution.",
        contact_work_p2: "Let’s connect! We would be glad to hear from you.",
          business_allfield:"All fields marked with * are required.",
          business_companyname:"Company Name *",
          business_fullname:"Full Name *",
          business_contact:"Contact Number *",
          business_product:"Product",
          business_email:"Email Address *",
          business_message:"Message *",
          business_ccc:"Coated Calcium Carbonate",
          business_ucc:"Uncoated Calcium Carbonate",
          business_pcc:"Precipitated Calcium Carbonate",
          business_gccd:"Ground Calcium Carbonate Dispersion",
          business_pccd:"Precipitated Calcium Carbonate Dispersion",
          business_imagecode:"What code is in the image? *",
          business_send:"Submit",
          workwithus_attachment:"Attachment"
      }
    },
    cn:{
        button:{
        home: '主页',
        aboutus: '关于我们',
        whoweare: '我们是谁',
        ourbrand: '品牌定位',
        visionandmission: '愿景和使命',
        ourhistory: '我们的历史',
        qualityandserviceassurance: '质量与服务保证',
        awardsandrecognition: '奖项与认可',
        products: '我们的产品',
        application: '产品用途',
        producttypes: '产品种类',
        facilities: '我们的设施',
        productionplants: '生产厂房',
        quarries: '采石场',
        operationworkflow: '操作流程',
        media: '媒体库',
        latestnews: '最新消息',
        corporatevideo: '企业视频',
        corporatebrochure: '企业宣传册',
        csr: '心怀社会',
        caringforsociety: '心怀社会',
        contact: '联系方式',
        contactdetails: '联系方式',
        businessenquiry: '商业洽询',
        workwithus: '加入我们',
        comingsoon: '(即将推出)'
      },
      slider_body: {
        landing: '征达私人有限公司是马来西亚碳酸钙的领导生产商之一。我们通过提供高品质产品与卓越服务的方式，为我们的客户提供历久弥新的价值。',
        about: '',
        products: '',
        facilities: '',
      },
      body: {
        footer_international: "语言",
        footer_connect: "联系我们",
        landing_slide: '征达私人有限公司是马来西亚碳酸钙的领导生产商之一。我们通过提供高品质产品与卓越服务的方式，为我们的客户提供历久弥新的价值。',
        about_slide: '我们与客户和商业伙伴沟通时，审慎认真、耐心相待，以更全面了解他们的需求。客户可以信任我们为他们提供更有价值的定制化解决方案，并引导他们迈向业务的可续性发展，提升卓越表现。',
        ///about
        aboutus_whoweare_title: '我们是谁',
        aboutus_whoweare_p1: '征达私人有限公司是马来西亚碳酸钙的领导生产商之一。我们通过提供高品质产品与卓越服务的方式，为我们的客户创造持久的价值。',
        aboutus_whoweare_title2: '全力以赴',
        aboutus_whoweare_title3: '超群出众',
        aboutus_whoweare_p2_1: '征达私人有限公司在1985年成立，现已发展为马来西亚高品质碳酸钙粉末和碳酸钙浆料的领导生产商之一。我们拥有一支具备逾30年经验和行业知识的专业团队，我们以替客户和商业伙伴创造历久弥新的价值、尽心尽职为不同行业提供多种优质产品和服务而著称。',
        aboutus_whoweare_p2_2: '秉持着坚定的信念及时刻信守承诺，我们确保以最高的诚信、诚意和效率来营运业务。',
        aboutus_whoweare_p2_3: '征达一直紧跟当前的行业趋势、创新科技和技术发展，且坚持着征达创始人的愿景和理念，持续不断开拓国际市场，以朝向更高的目标迈进。',
        ///brand
        aboutus_brand_title1: '我们的',
        aboutus_brand_title2: '品牌定位',
        aboutus_brand_title3: '品牌宗旨',
        aboutus_brand_title4: '我们的',
        aboutus_brand_title5: '品牌个性概观',
        aboutus_brand_grey1: '品牌定位与宗旨',
        aboutus_brand_grey2: '品牌个性概观',
        aboutus_logo_bg1: '可靠',
        aboutus_logo_bg2: '坚定',
        aboutus_logo_bg3: '坚韧',
        aboutus_logo_def_title: '我们的',
        aboutus_logo_def_title2: '标志',
        aboutus_logo_def01: '我们的品牌定位和宗旨是我们在沟通、行动和互动时的准则。 籍由坚持我们的品牌个性，我们确保我们在妥善地为客户提供服务之余，也建立起了更强大的征达。',
        aboutus_logo_def02: '我们的身份特征',
        aboutus_logo_key1: '稳定性与敏捷性之间的平衡',
        aboutus_logo_key2: '反映出对细节和精度的赏识',
        aboutus_logo_key3: '提供独特、诚实的表达',
        aboutus_logo_key4: '精致、充满激情和抱负',
        aboutus_logo_def03: '我们的标志',
        aboutus_logo_def04: '可靠 | 坚定 | 坚韧, ',
        aboutus_logo_def05: '作为自我声明和存在的价值。',
        aboutus_logo_purpose1_1: '重视',
        aboutus_logo_purpose1_2: '伙伴关系',
        aboutus_logo_purpose2_1: '',
        aboutus_logo_purpose2_2: '赋权',
        aboutus_logo_purpose2_3: '和',
        aboutus_logo_purpose2_4: '乐观',
        aboutus_logo_purpose3_1: '',
        aboutus_logo_purpose3_2: '务实',
        aboutus_logo_purpose3_3: '而',
        aboutus_logo_purpose3_4: '扎实',
        aboutus_logo_purpose4_1: '塑造员工',
        aboutus_logo_purpose4_2: '积极的态度',
        aboutus_logo_purpose4_3: '',
        aboutus_brand_p1: '在拥有超过30年的经验和行业专业知识的基础下，我们通过为不同行业提供多种优质产品和卓越服务，为客户创造历久弥新的价值。',
        aboutus_brand_p2: '在可靠、坚定及有弹性的核心价值观驱动下，我们不断努力为客户提供高品质的产品和服务。 我们的谦卑及真诚态度，使我们成为客户更好的合作伙伴。',
        aboutus_brand_p3: '在征达，我们把客户视为业务的合作伙伴和亲密朋友。 我们致力于为客户提供个性化的支持，以运营和发展一个成功的业务。',
        aboutus_brand_p4: '我们化繁为简,不断寻找更高效的方法来支持我们的客户，并为客户跟进最新的动态。',
        // aboutus_brand_p5: 'Our brand positioning and purpose are the guideline to our every communication, action and interaction. By adhering to our brand personality, we ensure that we appropriately serve our customers as well as build a stronger Zantat.',
        aboutus_brand_p5: '我们的品牌定位和宗旨是我们在沟通、行动和互动时的准则。 籍由坚持我们的品牌个性，我们确保我们在妥善地为客户提供服务之余，也建立起了更强大的征达。',
        ///vision
        aboutus_vision_title: '我们的愿景和使命',
        aboutus_vision_title1: '愿景',
        aboutus_vision_p1: '建设成为生产碳酸钙产品的顶尖制造商和提供最高价值的可靠合作伙伴。',
        aboutus_mission_title: '使命',
        aboutus_mission_p1: '通过强大的客户关系、创新的研发，以及最先进的设施，兑现我们为客户提供高质量产品和优质服务的承诺。',

        ///award
        aboutus_award_title: '奖项与认可',
        aboutus_award_p1: '我们对本身获认可为马来西亚碳酸钙行业领导者之一的成就感到自豪。这些极具声望的奖项证明了我们不断追求卓越的精神，而我们将继续提供优质的产品与服务，为客户增值。',
        aboutus_award_2007p1: '2007年中小型企业 年度奖项',
        aboutus_award_2007p2: '2007年金牛奖',
        aboutus_award_2007p3: '最杰出中小型企业百强得主',

        aboutus_award_2008p1: '2008年金牛奖',
        aboutus_award_2008p2: '最杰出中小型企业百强得主',

        aboutus_award_2009p1: '2009年金牛奖',
        aboutus_award_2009p2: '最杰出中小型企业百强得主',

        aboutus_award_2010p1: '2010年金牛奖',
        aboutus_award_2010p2: '最杰出中小型企业百强得主',

        aboutus_award_2011p1: '2011年金牛奖',
        aboutus_award_2011p2: '最杰出中小企业第九名',

        aboutus_award_2012p1: '2012年金牛奖',
        aboutus_award_2012p2: '最杰出中小型企业百强得主',

        aboutus_award_2013p1: '2013年金鹰奖',
        aboutus_award_2013p2: '卓越金鹰第八名',

        aboutus_award_2015p1: '2015年金鹰奖',
        aboutus_award_2015p2: '卓越金鹰第三名',
        aboutus_award_2015p3: '2015年星州企业楷模奖',

        aboutus_award_2016p1: '2016年金鹰奖',
        aboutus_award_2016p2: '超级金鹰第三名',

        aboutus_award_2017p1: '2017年金鹰奖',
        aboutus_award_2017p2: '超级金鹰第二名',

        aboutus_award_2018p1: '2018年金鹰奖',
        aboutus_award_2018p2: '超级金鹰二十强得主',
        ///quality
        aboutus_quality_title: '质量与服务保证',
        aboutus_quality_p1: '在征达，我们以超越客户的要求与期望深感自豪。 我们坚决遵守“提供节约成本的解决方案以创建绿色产品”的政策。',
        aboutus_quality_p2: '我们对质量和环境的承诺函盖我们所提供的产品和服务，通过实施 ISO9001：2015 品质管理体系的认证、ISO14001：2015环境管理体系、危害分析关键控制点（HACCP）、良好生产规范（GMP）以及马来西亚伊斯兰发展局（JAKIM）清真认证。',

        ///history
        aboutus_history_title: '我们的历史',
        aboutus_history_p1: '自1986年起，征达一直是坚韧、勤奋、坚定和诚信的象征。这些持久不衰的品质一直是征达的引导准则，并使征达成长为马来西亚碳酸钙的领导生产商之一。',

        aboutus_history_1986p1: '征达吉隆坡厂房使用球磨机生产活性碳酸钙，并供应给聚氯乙烯（PVC）和橡胶行业。',

        aboutus_history_1997p1: '推介了一个乳胶手套行业的专用产品，即以“SUPERLITE”为品牌的超细沉淀碳酸钙粉末，具备防粘剂的功效。  ',

        aboutus_history_2000p1: '收购了Calrock 公司为征达的姐妹公司，以生产粗粒级碳酸钙粉末。',

        aboutus_history_2002p1: '征达位于吉隆坡的厂房专门特为乳胶手套业者研发了高附加值的碳酸钙浆料；征达是马来西亚首家将填料引入乳胶手套行业的碳酸钙供应商。',

        aboutus_history_2005p1: '在霹雳州怡保收购一个面积达10英亩的石灰岩矿场。',

        aboutus_history_2010p1: '获颁为期60年的采矿牌照并开始兴建怡保厂房。',

        aboutus_history_2011p1: '征达在怡保的第一间厂房正式投入生产，碳酸钙粉末的年产量高达9万公吨。',

        aboutus_history_2012p1: '征达开始扩张市场至海外，并把印度视为目标市场之一。',

        aboutus_history_2014p1: '征达在怡保厂房所展开的第二阶段扩建工程竣工，年产量提高至18万公吨。',

        aboutus_history_2015p1: '全年总出口量达到14万公吨。',

        aboutus_history_2016p1: '征达欢庆成立30周年。',

        aboutus_history_2017p1: '第三期扩建工程于首季启动，扩建区包括了占地12,500平方尺的双层办公大楼，以及一栋面积达110,000平方尺的厂房。',

        aboutus_history_2018p1: '征达在怡保厂房的第三期扩建工程竣工，并于第三季正式投入生产，每年能生产高达10万公吨优质碳酸钙粉末。',

        ///product
        product_title1: '天然',
        product_title2: '宝藏',
        product_divider1: '品质',
        product_p1: '碳酸钙',
        product_p1_2: ' 存在于自然界之中，以一种白色方解石矿物的形态存在于石灰岩和其他岩石内。加工碳酸钙有数种形式，包括天然或活性碳酸钙粉末，以及碳酸钙浆料。',
        product_title3: '产品用途',
        product_p2_1: '',
        product_p2_2: '重质碳酸钙（GCC）',
        product_p2_3: '及',
        product_p2_4: '轻质碳酸钙（PCC）',
        product_p2_5: '在许多制造业获广泛使用为生产的原材料之一，当中包括填充母料、聚氯乙烯（PVC）管、塑料制品、乳胶手套、橡胶、油漆和涂料、纸张、塑料薄膜等。',

        product_s1_title: '填充母料',
        product_s1_p1: '碳酸钙填充母料用于改善塑料产品的物理和化学性能。',

        product_s2_title: '聚氯乙烯（PVC）管',
        product_s2_p1: '在聚氯乙烯管中，碳酸钙可提高其性能和强度，同时减少昂贵的树脂含量。',

        product_s3_title: '塑料制品',
        product_s3_p1: '碳酸钙提高了塑料制品的冲击韧性，并提供高光泽度、平滑光洁度和洁白度。',

        product_s4_title: '乳胶手套',
        product_s4_p1: '碳酸钙浆料在天然橡胶和腈乳胶手套的生产中充当填充剂，可降低成本之余，又不影响其延伸率和拉伸强度。',

        product_s5_title: '橡胶',
        product_s5_p1: '碳酸钙增强了橡胶的物理特性，例如其韧性和强度。',

        product_s6_title: '油漆和涂料',
        product_s6_p1: '油漆和涂料工业所使用的颜料包括碳酸钙粉末，用于控制颜色和降低成本。',

        product_s7_title: '纸张',
        product_s7_p1: '碳酸钙用作改善纸张的洁白度和亮度，同时可通过代替纤维和颜料的方式来降低成本。',

        product_s8_title: '其他',
        product_s8_p1: '碳酸钙粉末因其多用途而获广泛使用于各种行业。',

        product_type_title1: '产品种类',
        product_type_s1_title: '碳酸钙粉末',
        product_type_s1_p1: '一般上，碳酸钙粉末在塑料、纸张、油漆、涂料和橡胶的生产中被用作填充剂。 它也可用作功能性填充剂，以改变最终产品的化学和物理性质。 碳酸钙粉末的自然洁白度使其成为材料增白的最廉价材料之一。',
        product_type_s2_title: '碳酸钙浆料',
        product_type_s2_p1: '碳酸钙浆料形式是用作于填充材料，并最大程度地节省材料和生产成本。 我们的碳酸钙浆料是专门开发用作乳胶手套和造纸工业的填料。',

        ///facilities
        facilities_productionplants_title: '生产厂房',
        facilitiesp1: '迄今为止，征达拥有4个制造厂房，其中三家厂房均设于战略性位置—霹雳州怡保，隶属征达私人有限公司和其姐妹公司岩石私人有限公司旗下，其庞大的建筑面积分别超过220,000平方尺和48,305平方尺；另一家厂房则位于吉隆坡甲洞，占40,000平方尺的营运空间。',
        facilitiesp2: '我们的生产厂房已通过国际品质管理体系认证（ISO）的标准，具备生产各种碳酸钙产品的能力，并着重于满足各种行业需求的质量。',
        facilitiescp1: "拓展",
        facilitiescp2: "绩效",
        facilitiest1: '怡保厂房',
        facilitiesp3: '我们于2000年在怡保收购了一家生产厂房，并于2011年建立新的第一期厂房开始扩展业务，着重于生产用作塑料、油漆和造纸业的碳酸钙粉末。 怡保厂房的第二期和第三期扩建工程分别于2014年和2017年竣工，合并的生产能力达320,000公吨。',
        facilitiesp3_1: '我们位于',
        facilitiesp3_2: 'PT24571 & PT21289, Kaw. Industri Batu Kapur Keramat Pulai, 31300 Kampung Kepayang, Perak, Malaysia. ',
        facilitiesp3_3: '在谷歌地图中找我们',
        facilitiesp3_4: '电话',
        facilitiesp3_5: '+605 357 1853 / +605 357 1463',
        facilitiesp3_6: ' •  传真',
        facilitiesp3_7: '+605 357 1407',
        facilitiest2: '吉隆坡厂房',
        facilitiesp4: '我们在吉隆坡的第一家厂房是于1986年开始投入运作。就在此时，我们开发了专门用于乳胶手套行业的碳酸钙浆料，这使征达成为首家为乳胶手套引入填料的碳酸钙制造商。今天，我们在吉隆坡的厂房已成为一家专门为乳胶手套行业生产碳酸钙浆料的厂房，年生产量为60,000公吨。',
        facilitiesp4_1: '我们位于',
        facilitiesp4_2: 'Lot 1013-B, Jalan 2/32A, 6 ½ Miles, Kepong Industrial Area, Jalan Kepong, 52100 Kuala Lumpur, Malaysia.',
        facilitiesp4_3: '在谷歌地图中找我们',
        facilitiesp4_5: '+603 6258 5552 / +603 6258 1985',
        facilitiesp4_7: '+603 6251 8766',
        facilities_quarries_title: '采石场',
        facilities_quarries_sp1: '我们拥有两个石灰岩的储量，其采矿租约的有效期分别至2068年和2070年。 这两个石灰岩储量的总面积为25英亩，石灰岩的总储量估计合共高达2200万公吨。',

        facilities_workflow_title1: '操作',
        facilities_workflow_title2: '流程',

        facilities_workflow_1_title1: '供给原料的准备与采石操作',
        facilities_workflow_1_title2: '',
        facilities_workflow_1p1: '爆破石灰石。',
        facilities_workflow_1p2: '液压破碎器压碎巨石后，转移到毗连的碎石厂，以分级成6乘9的石灰石块。 ',
        facilities_workflow_1p3: '把6乘9的石灰石块转移到另一个碎石厂进行清洗，并切割成40毫米至60毫米的石灰碎屑。',
        facilities_workflow_1p4: '40至60毫米的石灰碎屑运到征达，储存在有覆盖的区域，最低库存量为20,000公吨。',
        facilities_workflow_2_title1: '活性及非活性碳酸钙粉末的生产',
        facilities_workflow_2_title2: '',
        facilities_workflow_2p1: '把40毫米至60毫米的石灰碎屑送入输送带，以进行第一阶段的目视检查，并清除掉杂质。',
        facilities_workflow_2p2: '把40毫米至60毫米的石灰碎屑再度送入输送带，进行第二阶段的目视检查，并清除掉杂质。',
        facilities_workflow_2p3: '锤式粉碎机把40毫米至60毫米的石灰碎屑加工成为97%皆是10毫米以下的碎石。',
        facilities_workflow_2p4: '把碎石送入各个不同的磨机进行研磨，并分类为各种等级的非活性或天然碳酸钙粉末。',
        facilities_workflow_2p4_2: '成品：非活性/天然碳酸钙粉末。',

        facilities_workflow_3_title1: '硬脂酸活性过程',
        facilities_workflow_3_title2: '',
        facilities_workflow_3p1: '把硬脂酸熔化并过滤，再进行雾化过程',
        facilities_workflow_3p1_2: '成品：硬脂酸活性碳酸钙。',


        facilities_workflow_4_title1: '碳酸钙浆料的生产',
        facilities_workflow_4_title2: '',
        facilities_workflow_4p1: '把非活性或天然碳酸钙，与分散剂和其它化学添加剂混合。',
        facilities_workflow_4p2: '目视检查，清除杂质和进行湿磨过程。',
        facilities_workflow_4p3: 'Wet milling process.',
        facilities_workflow_4p4: '成品：碳酸钙浆料',


        facilities_workflow_5_title1: '运送给国内外的客户',
        facilities_workflow_5_title2: '',
        facilities_workflow_5p1: '运送给国内外的客户。',

        facilities_workflow_title2_1: '质量控制措施',
        facilities_workflow_title2_2: '',
        facilities_workflow_title2_3: '',

        facilities_workflow_qctitle1_1: '碳酸钙粉末',
        facilities_workflow_qctitle1_2: '',


        facilities_workflow_qc1: '每两小时从每个研磨机中保留样品以进行粒径（PSD）分析、白度、 亮度监测、水分含量、二辛酯（DOP）吸油率。',
        facilities_workflow_qc2: '配合采样过程，监控和记录机器的参数。',
        facilities_workflow_qc3: '每个货盘将获给予一个编号，包括一个保留在实验室中的样品，以作未来的参考。',
        facilities_workflow_qc4: '包装：25公斤纸袋、600公斤巨型袋、1.25公吨的巨型袋。',

        facilities_workflow_qctitle2_1: '碳酸钙浆料',
        facilities_workflow_qctitle2_2: '',

        facilities_workflow_qc2_1: '每两小时从每个研磨机中所保留的样品，将进行过程间检测（粒径分析、总固体含量、粘度和酸碱值（pH）。',
        facilities_workflow_qc2_2: '每个商品包装都获给予一个编号，包括一个保留在实验室中的样品，以作未来的参考。',
        facilities_workflow_qc2_3: '包装：金属桶、中型散装容器（IBC）、特制大型散装容器。',
        ///care for society
        news_title: "最新消息",
        comingsoon: "即将推出",
        news_p1: "绩效",
        news_np1: "Find out more in our ",
        news_np2: "FB Like Page",
        news_np3: "Zantat 30th Anniversary Employee and Business Partner Appreciation Dinner",

        ///care for society
        video_title: "CORPORATE VIDEOS",
        video_p1: "绩效",


        ///care for society
        care_title: "心怀社会",
        care_p1: "We seek to make a positive contribution in the local community and the environment by encouraging our people to make a difference through organising of various engaging initiatives and events.",
        care_p2: "We believe working relationships among employees extends beyond the boundaries of the workplace. Visits to underprivileged children’s home, contribution to the special needs and disabled community, blood donation drives and gathering for sports recreation sessions are some of the initiatives we have organised with our people.",
        care_p3: "We ensure strong and solid relationships are fostered and build through these social platforms.",

        ///care for society
        contact_details_title: "联系我们",
        contact_klplant: "征达私人有限公司 - 吉隆坡厂房",
        contact_ipohplant: "征达私人有限公司 - 怡保厂房",
        contact_klfactory: "吉隆坡厂房",
        contact_ipohfactory: "怡保厂房",

        contact_business_title: "商业洽询 ",
        contact_business_title2: "",
        contact_business_p1: "我们欢迎任何潜在的商业机会和合作伙伴洽询，请联络我们以获取更多相关资讯与讨论。",
        contact_business_p2: "征达，一直是您的信心保证。",
        contact_business_p3: "联系",

        contact_work_title: "加入我们",
        contact_work_p1: "我们欢迎您提出任何疑问，因为我们正积极招揽更多有创意和专业的人才。 了解我们公司如何发掘您的潜力，以及让您如何做出积极的贡献。",
        contact_work_p2: "连系我们吧！ 我们将会很高兴收到您的来信。",
          business_allfield:"所有标有 * 的栏目均为必填项。",
          business_companyname:"公司名称 *",
          business_fullname:"全名 *",
          business_contact:"联络号码 *",
          business_product:"商品",
          business_email:"电邮地址 *",
          business_message:"信息 *",
          business_ccc:"活性碳酸钙",
          business_ucc:"非活性碳酸钙",
          business_pcc:"沉淀碳酸钙",
          business_gccd:"重质碳酸钙浆料",
          business_pccd:"沉淀重质碳酸钙浆料",
          business_imagecode:"图中的验证码是什么？*",
          business_send:"提交",
          workwithus_attachment:"附件"
    },
  }
  }
});

import VueRellax from 'vue-rellax';
Vue.use(VueRellax)

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");
