<template>
  <div class="hello">
    <div class="hero"></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String
  }
};
</script>

<style scoped lang="scss">
.hero {
  width: 100%;
  height: 400px;
  background: #ddd;
}
</style>
